<script setup lang="ts">
const { t } = useT();
const { open } = useTaoModals();

const { depositInfoData, durationLeftResetStreak } = useTaoDepositStreak();

const activeDepositCount = computed(() => depositInfoData.value?.progress?.lastIndexOf(true) + 1);
const depositsTotals = computed(() => depositInfoData.value?.progress?.length);

const title = computed(() => (depositInfoData.value?.canWheel ? t("Get my prize") : t("Streak to win")));

const handleClick = () => {
	open("LazyOModalDepositStreak");
};
</script>

<template>
	<div class="banner" @click="handleClick">
		<ABadge variant="info" class="label" :size="40" background="var(--gradient-5)">
			<AText type="xx-small" :modifiers="['uppercase', 'nowrap', 'condensed', 'medium']" class="color-neutral">
				{{ t("New") }}
			</AText>
		</ABadge>
		<NuxtImg
			src="/nuxt-img/deposit-streak/icon-menu.webp"
			width="156"
			height="150"
			format="avif"
			loading="lazy"
			alt="tao-deposit"
		/>
		<div class="wrapper">
			<AText v-if="depositInfoData?.dailyWinStreakLimitExceeded" variant="topeka" class="text-coro">
				<i18n-t keypath="Available in: {key}">
					<template #key>
						<br />
						<AText variant="toledo" class="text-coro" :modifiers="['semibold']">
							{{ durationLeftResetStreak }}
						</AText>
					</template>
				</i18n-t>
			</AText>
			<template v-else>
				<AText :modifiers="['uppercase', 'bold']" gradient="var(--grahamstown)">
					<span>{{ title }}</span>
				</AText>
				<AText v-if="depositInfoData?.canWheel" variant="topeka" class="text-coro">
					{{ t("Prize Unlocked") }}
				</AText>
				<MDepositStreakLevelsBar v-else class="levels" :active="activeDepositCount" :levels="depositsTotals" />
			</template>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.banner {
	display: flex;
	align-items: center;
	gap: 8px;
	background-color: var(--cando) !important;
	border-radius: 10px;
	cursor: pointer;
	position: relative;

	.wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.levels {
		margin-top: 4px;
	}

	.label {
		display: inline-flex;
		position: absolute;
		left: -3px;
		top: -3px;
		border-radius: 33px;
		padding: 4px 8px;
		border: none;

		&:deep(span) {
			text-shadow: 0px 1px 1px var(--coronel);
		}
	}

	img {
		width: 48px;
		height: 48px;
		object-fit: cover;
	}
}
</style>
